import {
  Autocomplete,
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { BoxContent, BreakpointBox, CircularLoading } from "../../Atoms";
import { GenericInput, MessageBox, MessageIcon } from "../../Molecules";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import useAuth from "../../../../hooks/context/useAuth";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { HtmlTextEditor } from "../ReactQuillHtmlEditor";
import { Validation } from "./validationSchema";
import { useCommonData } from "../../../../hooks/useCommonData";
import CustomDatePicker from "../../Molecules/DatePicker";
import { DropImageZone } from "../DropZonePreview";
import { blue, green, red, teal } from "@mui/material/colors";
import { CustomModal } from "../Modal";
import { PrintComponent } from "../../Molecules/ReactPDF";

interface Props {
  userList: UsersAdmin[];
  product: Products;
  handleSubmit(val: any): void;
  isLoading?: boolean;
  isFileLoading?: boolean;
  removeFileAdmin?(imageID: number): void;
  submitLoading?: boolean;
  productSolicitationAdmin?(
    productID: number,
    solicitationType: "" | "removePackage" | "takeNewPhotos"
  ): void;
  deleteProduct?(id: number): void;
  sendNewMessage(data: INewMessage): void;
  readAllMessages(orderID?: number): void;
  getOrders?(suiteID: number): Promise<IOrderToBuyResponse>;
}

export function NewProductBox({
  userList,
  product,
  handleSubmit,
  isLoading,
  removeFileAdmin,
  submitLoading,
  productSolicitationAdmin,
  deleteProduct,
  sendNewMessage,
  readAllMessages,
  getOrders,
}: Props) {
  const { orderModal, adminOrder, shippmentModal } = useCommonData();
  const { isMobile } = useBreakpoints();
  const { getUserLocalStorage, language, isAdmin } = useAuth();
  const { validationSchema } = Validation(language);
  const { isSmalldesktop } = useBreakpoints();
  const { user } = getUserLocalStorage();
  const [confirmModal, setConfirmModal] = useState(false);
  const [cleanFileState, setCleanFileState] = useState(false);
  const [messageTooltip, setMessageTooltip] = useState(true);
  const [messageModal, setMessageModal] = useState(false);
  const [ordersToBuy, setOrdersToBuy] = useState<IOrderToBuy[]>([]);
  const [edit, setEdit] = useState({ order: false, link: false });

  const formik = useFormik<any>({
    initialValues: {
      name: "",
      description: "",
      kg: 0,
      itemsQuantity: 0,
      arrivedAt: new Date(),
      suiteId: 0,
      userName: "",
      orderID: 0,
      linkID: 0,
      file: [],
      oldFiles: [],
    },
    validationSchema,
    async onSubmit(values: any) {
      if (product?.id) {
        handleSubmit({
          type: "update",
          data: {
            ...values,
            productID: product?.id,
            arrivedAt: new Date(values.arrivedAt).toISOString(),
          },
        });
        setCleanFileState((old) => !old);
      } else {
        handleSubmit({
          type: "create",
          data: {
            ...values,
            arrivedAt: new Date(values.arrivedAt).toISOString(),
          },
        });
        formik.resetForm();
      }
    },
  });

  useEffect(() => {
    // controla se o modal vai ser de cadastro ou edição

    formik.setFieldValue("name", product?.name ?? "");
    formik.setFieldValue("description", product?.description ?? "");
    formik.setFieldValue("kg", product?.kg ?? 0);
    formik.setFieldValue("itemsQuantity", product?.itemsQuantity ?? 0);
    formik.setFieldValue(
      "arrivedAt",
      product?.id ? new Date(product?.arrivedAt) : new Date()
    );
    formik.setFieldValue("suiteId", product?.Suite?.id ?? 0);

    formik.setFieldValue(
      "orderID",
      product?.LinksOrderToBuy?.length > 0
        ? product?.LinksOrderToBuy[0]?.orderToBuyId ?? 0
        : 0
    );
    formik.setFieldValue(
      "linkID",
      product?.LinksOrderToBuy?.length > 0
        ? product?.LinksOrderToBuy[0]?.id ?? 0
        : 0
    );
    formik.setFieldValue(
      "oldFiles",
      product?.ProductImages?.length > 0 ? product?.ProductImages : []
    );

    setTimeout(() => {
      setMessageTooltip(false);
    }, 5000);

    // eslint-disable-next-line
  }, [product]);

  const disableInput = () => {
    if (product?.leftInStock <= 0) {
      return true;
    }
    if (product && user?.user?.role !== "admin" && product?.id) {
      return true;
    }
    return false;
  };

  const mapList = userList?.map(({ user: { name }, suite }) => ({
    label: `${name} - suite ${suite[0].id}`,
    value: suite[0].id,
  }));

  const mapOrders = async (suiteID: number) => {
    const orders = getOrders && (await getOrders(suiteID));
    if (orders) {
      // eslint-disable-next-line
      setOrdersToBuy(orders?.data);
    }
  };
  useEffect(() => {
    if (formik.values.suiteId && !product?.id) {
      mapOrders(formik.values.suiteId);
    }
    // eslint-disable-next-line
  }, [formik.values.suiteId]);

  useEffect(() => {
    if (
      (formik.values.suiteId && product?.id && edit.order) ||
      (formik.values.suiteId && product?.id && edit.link)
    ) {
      mapOrders(formik.values.suiteId);
    }
    // eslint-disable-next-line
  }, [formik.values.suiteId, edit.order, edit.link]);

  const defaultValue = userList?.find(
    (users) => users?.suite[0]?.id === product?.Suite?.id
  );

  const disableRemovePKCbtn = () => {
    if (product?.adminRemovePackage) return true;
    if (product?.removePackage) return false;
    return true;
  };

  const disableUpdatePhotosBTN = () => {
    if (product?.adminTakeNewPhotos) return true;
    if (product?.takeNewPhotos) return false;
    return true;
  };

  const confirmAction = () => {
    deleteProduct && deleteProduct(product?.id);
  };

  const hasNewMessage = () => {
    const messages = product?.WarningMessage;
    if (!messages) return false;
    if (messages?.length === 0) return false;
    if (!messages[0]?.read && user?.user?.id !== messages[0]?.usersId) {
      if (user?.user?.role === messages[0]?.type) {
        return false;
      }
      return true;
    }
    return false;
  };

  const isAdminAndHaveProduct = product && user?.user?.role === "admin";

  const renderCompleteOrder = () => {
    if (!product?.id) return true;
    if (edit.order) return true;
    return false;
  };

  const renderCompleteLink = () => {
    if (!product?.id) return true;
    if (edit.link) return true;
    return false;
  };
  return (
    <BoxContent config={{ minWidth: "180px", p: 3 }}>
      {isLoading || submitLoading ? (
        <CircularLoading text={submitLoading ? "Enviando" : ""} />
      ) : (
        <>
          {isAdminAndHaveProduct && (
            <PrintComponent
              productID={product?.id}
              suiteID={product?.suiteId}
            />
          )}
          <Box display="flex" width="100%" justifyContent={"flex-start"} mb={2}>
            {product?.id && (
              <MessageIcon
                hasNewMessage={hasNewMessage()}
                openModal={() => setMessageModal(true)}
                tooltipOpen={messageTooltip}
                text={orderModal.tooltip}
                chatButton
              />
            )}
          </Box>
          {disableInput() ? (
            <></>
          ) : (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              options={mapList}
              sx={{ width: "100%", mb: 1 }}
              onChange={(_e, suite) =>
                formik.setFieldValue("suiteId", suite?.value)
              }
              defaultValue={{
                label: `${defaultValue?.user?.name} - suite ${defaultValue?.suite[0]?.id}`,
                value: defaultValue?.suite[0]?.id,
              }}
              loading
              getOptionLabel={(option) => option.label ?? ""}
              renderInput={(params) => <TextField {...params} label="Suite" />}
              renderOption={(props, option) => (
                <ListItem
                  {...props}
                  sx={{ "&:hover": { bgcolor: "lightgray" }, p: 2 }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width={"100%"}
                  >
                    <Typography>{option?.label}</Typography>
                    <Typography>Suite {option?.value}</Typography>
                  </Box>
                </ListItem>
              )}
            />
          )}
          {renderCompleteOrder() ? (
            <Box display="flex" width="100%">
              <Autocomplete
                disabled={formik.values.suiteId === 0 || disableInput()}
                disablePortal
                id="combo-box-demo"
                options={ordersToBuy?.map((order) => ({
                  label: `${order?.title} - order ${order?.id}`,
                  value: order?.id,
                }))}
                sx={{ width: "100%", mb: 1 }}
                onChange={(_e, suite) =>
                  formik.setFieldValue("orderID", suite?.value)
                }
                loading
                getOptionLabel={(option) => option.label ?? ""}
                renderInput={(params) => (
                  <TextField {...params} label="Orders" />
                )}
                renderOption={(props, option) => (
                  <ListItem
                    {...props}
                    sx={{ "&:hover": { bgcolor: "lightgray" }, p: 2 }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width={"100%"}
                    >
                      <Typography>{option?.label}</Typography>
                      <Typography>order {option?.value}</Typography>
                    </Box>
                  </ListItem>
                )}
              />
              {product && edit.order && (
                <Button
                  sx={{ ml: 1, height: 56 }}
                  variant="contained"
                  onClick={() => setEdit({ ...edit, order: false })}
                  style={{ color: "white" }}
                >
                  X
                </Button>
              )}
            </Box>
          ) : (
            <Box
              display="flex"
              width="100%"
              justifyContent={"center"}
              alignItems={"center"}
              mb={1}
            >
              <Typography style={{ width: "80%", overflow: "hidden" }}>
                Order n°{" "}
                {product?.LinksOrderToBuy?.length > 0 &&
                  product?.LinksOrderToBuy[0]?.orderToBuyId}
              </Typography>
              {isAdmin && (
                <Button
                  disabled={!isAdmin}
                  sx={{ ml: 1 }}
                  variant="contained"
                  onClick={() => setEdit({ order: true, link: true })}
                  style={{ color: "white" }}
                >
                  {shippmentModal.edit}
                </Button>
              )}
            </Box>
          )}

          {renderCompleteLink() ? (
            <Box display="flex" width="100%">
              <Autocomplete
                disabled={formik.values.orderID === 0 || disableInput()}
                disablePortal
                id="combo-box-demo"
                options={
                  ordersToBuy
                    ?.find((or) => or?.id === formik?.values?.orderID)
                    ?.LinksOrderToBuy?.map((link) => {
                      if (link?.arrivedProductsId) {
                        return {
                          label: `*${
                            link?.arrivedProductsId && "Já cadastrado"
                          }* ${link?.title} - link ${link?.id}`,
                          value: link?.id,
                        };
                      }
                      return {
                        label: `${link?.title} - link ${link?.id}`,
                        value: link?.id,
                      };
                    }) ?? []
                }
                sx={{ width: "100%", mb: 1 }}
                onChange={(_e, suite) =>
                  formik.setFieldValue("linkID", suite?.value)
                }
                loading
                getOptionLabel={(option) => option.label ?? ""}
                renderInput={(params) => <TextField {...params} label="Link" />}
                renderOption={(props, option) => (
                  <ListItem
                    {...props}
                    sx={{ "&:hover": { bgcolor: "lightgray" }, p: 2 }}
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      width={"100%"}
                    >
                      <Typography>{option?.label}</Typography>
                      <Typography>link {option?.value}</Typography>
                    </Box>
                  </ListItem>
                )}
              />
              {product && edit.link && (
                <Button
                  sx={{ ml: 1, height: 56 }}
                  variant="contained"
                  onClick={() => setEdit({ ...edit, link: false })}
                  style={{ color: "white" }}
                >
                  X
                </Button>
              )}
            </Box>
          ) : (
            <Box
              display="flex"
              width="100%"
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography style={{ width: "80%", overflow: "hidden" }}>
                link id{" "}
                {product?.LinksOrderToBuy?.length > 0 &&
                  product?.LinksOrderToBuy[0]?.id}{" "}
                - name:{" "}
                {product?.LinksOrderToBuy?.length > 0 &&
                  product?.LinksOrderToBuy[0]?.title}
              </Typography>

              {isAdmin && (
                <Button
                  disabled={!isAdmin}
                  sx={{ ml: 1 }}
                  variant="contained"
                  onClick={() => setEdit({ ...edit, link: true })}
                  style={{ color: "white" }}
                >
                  {shippmentModal.edit}
                </Button>
              )}
            </Box>
          )}

          {formik.values.linkID !== 0 && (
            <Box display={"flex"} gap={2} mb={1} mt={1}>
              <Button
                onClick={async () => {
                  if (!product?.id) {
                    await navigator.clipboard.writeText(
                      ordersToBuy
                        ?.find((or) => or?.id === formik?.values?.orderID)
                        ?.LinksOrderToBuy?.find(
                          (link) => link?.id === formik?.values?.linkID
                        )?.link ?? ""
                    );
                  }
                  if (product?.id) {
                    await navigator.clipboard.writeText(
                      product?.LinksOrderToBuy[0]?.link
                    );
                  }
                }}
                sx={{
                  bgcolor: blue[400],
                  "&:hover": { bgcolor: blue[500] },
                }}
                variant="contained"
              >
                Copy <FileCopyOutlinedIcon sx={{ ml: 1 }} />
              </Button>
              <Button
                variant="contained"
                sx={{
                  bgcolor: teal[300],
                  "&:hover": { bgcolor: teal[500] },
                }}
              >
                <a
                  href={
                    product?.id
                      ? product?.LinksOrderToBuy[0]?.link
                      : ordersToBuy
                          ?.find((or) => or?.id === formik?.values?.orderID)
                          ?.LinksOrderToBuy?.find(
                            (link) => link?.id === formik?.values?.linkID
                          )?.link ?? ""
                  }
                  style={{ textDecoration: "none", color: "white" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  Open <OpenInNewIcon sx={{ ml: 1, mb: -0.7 }} />
                </a>
              </Button>
            </Box>
          )}

          {product?.leftInStock <= 0 && (
            <FormHelperText error sx={{ fontSize: 20 }}>
              Este item não tem mais estoque
            </FormHelperText>
          )}
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => formik.setFieldValue(name, value)}
            error={formik?.touched?.name && formik?.errors?.name ? true : false}
            errorMessage={formik?.touched?.name ? formik?.errors?.name : ""}
            value={formik?.values?.name}
            placeholder={orderModal.name}
            inputName="name"
            disabled={disableInput()}
          />
          <HtmlTextEditor
            onChange={(val) => formik.setFieldValue("description", val)}
            value={formik.values.description}
            disabled={disableInput()}
          />

          <BreakpointBox props={{ mt: isMobile ? 10 : 6 }}>
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                formik.setFieldValue(name, Number(value))
              }
              error={formik?.touched?.kg && formik?.errors?.kg ? true : false}
              errorMessage={formik?.touched?.kg ? formik?.errors?.kg : ""}
              value={formik?.values?.kg}
              placeholder={orderModal.kg}
              inputName="kg"
              inputType="number"
              disabled={disableInput()}
              startAdornment={
                <InputAdornment position="start">g</InputAdornment>
              }
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                formik.setFieldValue(name, Number(value))
              }
              error={
                formik?.touched?.itemsQuantity && formik?.errors?.itemsQuantity
                  ? true
                  : false
              }
              errorMessage={
                formik?.touched?.itemsQuantity
                  ? formik?.errors?.itemsQuantity
                  : ""
              }
              value={formik?.values?.itemsQuantity}
              placeholder={orderModal.quantity}
              inputName="itemsQuantity"
              inputType="number"
              disabled={disableInput()}
            />
            <CustomDatePicker
              onChangeDate={(val) => formik.setFieldValue("arrivedAt", val)}
              label={orderModal.arrivedAt}
              date={formik.values.arrivedAt}
              errorMessage={
                formik?.touched?.arrivedAt ? formik?.errors?.arrivedAt : ""
              }
              error={
                formik?.touched?.arrivedAt && formik?.errors?.arrivedAt
                  ? true
                  : false
              }
              disabled={disableInput()}
            />
          </BreakpointBox>

          <DropImageZone
            oldFiles={product?.ProductImages}
            handleChangeFiles={(images) => formik.setFieldValue("file", images)}
            removeFileAdmin={removeFileAdmin}
            cleanFileState={cleanFileState}
            disabled={disableInput()}
          />

          {user.user.role === "admin" && product?.id && (
            <>
              <Button
                sx={{ mt: 3 }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  productSolicitationAdmin &&
                    (await productSolicitationAdmin(
                      product?.id,
                      "removePackage"
                    ));
                  formik.handleSubmit();
                }}
                disabled={disableRemovePKCbtn()}
              >
                Remover da caixa
              </Button>
              <Button
                sx={{ mt: 3 }}
                fullWidth
                variant="contained"
                onClick={async () => {
                  productSolicitationAdmin &&
                    (await productSolicitationAdmin(
                      product?.id,
                      "takeNewPhotos"
                    ));
                  formik.handleSubmit();
                }}
                disabled={disableUpdatePhotosBTN()}
              >
                Enviar novas fotos
              </Button>

              <Button
                sx={{
                  mt: 3,
                  background: red[500],
                  "&:hover": { bgcolor: red[200] },
                }}
                fullWidth
                variant="contained"
                onClick={() => {
                  setConfirmModal(true);
                }}
              >
                Deletar produto
              </Button>
            </>
          )}
          {!disableInput() && (
            <Button
              sx={{ mt: 3 }}
              fullWidth
              variant="contained"
              onClick={() => formik.handleSubmit()}
            >
              {product?.id ? "Update" : "Criar"}
            </Button>
          )}
        </>
      )}

      <CustomModal
        openModal={confirmModal}
        setOpenModal={() => setConfirmModal(false)}
        width={isSmalldesktop ? "90%" : "30%"}
      >
        <Box display={"flex"} justifyContent={"center"} mb={5} width={"100%"}>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
          >
            <Typography mb={2} width={315} sx={{ wordBreak: "break-word" }}>
              Deseja deletar o produto? Esta ação é irreversível
            </Typography>

            <Box display={"flex"} justifyContent={"center"} gap={5}>
              <Button
                sx={{ bgcolor: green[500], color: "white" }}
                style={{ width: 100 }}
                onClick={confirmAction}
              >
                {adminOrder.confirmBtn}
              </Button>
              <Button
                sx={{ bgcolor: red[500], color: "white" }}
                style={{ width: 100 }}
                onClick={() => setConfirmModal(false)}
              >
                {adminOrder.cancelBtn}
              </Button>
            </Box>
          </Box>
        </Box>
      </CustomModal>

      <CustomModal
        openModal={messageModal}
        setOpenModal={setMessageModal}
        width={isSmalldesktop ? "90%" : "30%"}
      >
        <MessageBox
          data={product?.WarningMessage}
          sendMessage={(newMessage) =>
            sendNewMessage({
              description: newMessage,
              orderID: product?.id,
              type: user?.user?.role,
              userID: user?.user?.id,
              messageType: "product",
            })
          }
          readMessages={() => readAllMessages(product?.id)}
        />
      </CustomModal>
    </BoxContent>
  );
}
