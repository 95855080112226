import { useEffect, useState } from "react";
import { BoxWithTitle } from "../../../components/AtomicDesign/Molecules";
import useAuth from "../../../hooks/context/useAuth";
import { useTranslate } from "../../../hooks/useTranslate";
import {
  AddressConfig,
  DeadlinesConfig,
  PaymentOptionsConfig,
  PricesConfig,
  SistemUseConfig,
  TermsAndConditionsConfig,
  WorkHoursConfig,
} from "../../../components/AtomicDesign/Organisms";
import {
  getSystemConfigService,
  updateSystemConfigService,
} from "../../../services/siteConfigService";
import {
  CircularLoading,
  FadeInContainer,
} from "../../../components/AtomicDesign/Atoms";
import { Box } from "@mui/material";
import { useBreakpoints } from "../../../hooks/useBreakpoins";

export type LoadingConfigs = {
  data: boolean;
  shoppingAddress?: boolean;
  systemUse?: boolean;
  workHours?: boolean;
  deadlines?: boolean;
  prices?: boolean;
  paymentOptions?: boolean;
};

type Fields =
  | "shoppingAddress"
  | "systemUse"
  | "workHours"
  | "deadlines"
  | "termsAndConditions"
  | "prices"
  | "paymentOptions";

export function SiteConfigAdmin() {
  const { strings } = useTranslate();
  const { language } = useAuth();
  const { isMobile, isSmalldesktop } = useBreakpoints();
  const [config, setConfig] = useState({} as SystemConfig);
  const [loading, setLoading] = useState({} as LoadingConfigs);

  const getConfig = async (loading?: boolean) => {
    if (loading) {
      setLoading((old) => ({ ...old, data: true }));
    }
    try {
      const response = await getSystemConfigService();
      setConfig(response);
    } catch {
    } finally {
      setLoading((old) => ({ ...old, data: false }));
    }
  };

  const updateConfig = async (val: any, type: Fields) => {
    setLoading((old) => ({ ...old, [type]: true }));
    const payload = {
      data: { ...val },
    };

    try {
      await updateSystemConfigService(payload);
    } catch {
    } finally {
      setLoading((old) => ({ ...old, [type]: false }));
      getConfig();
    }
  };

  useEffect(() => {
    getConfig(true);
  }, []);

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={5}
        p={1}
      >
        {/* <Button variant="contained" onClick={async () => await updateThings()}>
          update
        </Button> */}
        <BoxWithTitle
          title={strings.siteConfigPage.title[language]}
          width="100%"
          childrenProps={{ height: "100%" }}
          padding={2}
        >
          {loading.data ? (
            <CircularLoading />
          ) : (
            <FadeInContainer>
              <Box
                display={"flex"}
                gap={1}
                flexWrap={isMobile || isSmalldesktop ? "wrap" : "nowrap"}
              >
                <AddressConfig
                  addressConfig={config?.shoppingAddress}
                  onSubmit={(val) => updateConfig(val, "shoppingAddress")}
                  loading={loading}
                />
                <PricesConfig
                  loading={loading}
                  pricesConfig={config}
                  onSubmit={(val) => updateConfig(val, "prices")}
                />
              </Box>

              <PaymentOptionsConfig
                loading={loading}
                onSubmit={(val) => updateConfig(val, "paymentOptions")}
                paymentOptions={config?.paymentOptions}
              />
              <WorkHoursConfig
                loading={loading}
                onSubmit={(val) => updateConfig(val, "workHours")}
                workHoursConfig={config?.workHours}
              />

              <SistemUseConfig
                loading={loading}
                onSubmit={(val) => updateConfig(val, "systemUse")}
                sistemUseConfig={config?.systemUse}
              />
              <TermsAndConditionsConfig
                loading={loading}
                onSubmit={(val) => updateConfig(val, "termsAndConditions")}
                termsAndConditionsConfig={config?.termsAndConditions}
              />
              <DeadlinesConfig
                loading={loading}
                onSubmit={(val) => updateConfig(val, "deadlines")}
                deadlinesConfig={config?.deadlines}
              />
            </FadeInContainer>
          )}
        </BoxWithTitle>
      </Box>
    </FadeInContainer>
  );
}
