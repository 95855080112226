import { createContext, useEffect, useState, useContext } from "react";
import {
  changePasswordApi,
  forgotPasswordApi,
  getUserApi,
  loginApi,
  verifyCodeForgotPasswordApi,
} from "../../services/authService";

const AuthContext = createContext({} as IUseAuthContext);

export function setUserLocalStorage(loginData: LoginProps) {
  localStorage.setItem("@treasurebox_user", JSON.stringify(loginData.data));
  localStorage.setItem("@treasurebox_token", JSON.stringify(loginData.token));
}

export function getUserLocalStorage() {
  const jsonUser = localStorage.getItem("@treasurebox_user");
  const jsonToken = localStorage.getItem("@treasurebox_token");
  const jsonLanguage = localStorage.getItem("@treasurebox_language");

  return {
    user: !jsonUser ? null : JSON.parse(jsonUser),
    token: !jsonToken ? null : JSON.parse(jsonToken),
    language: !jsonLanguage ? "english" : JSON.parse(jsonLanguage),
  };
}

type AuthProviderProps = {
  children: React.ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [user, setUser] = useState({} as User);
  const [token, setToken] = useState("" as string);
  const [loginError, setLoginError] = useState(false);
  const [language, setLanguage] = useState("english" as ILanguageOption);
  const [siteConfig, setSiteConfig] = useState({} as SystemConfig);
  const [alerts, setAlerts] = useState([] as AlertsConfigProps[]);
  const [userLoadingData, setUserLoadingData] = useState(false);

  useEffect(() => {
    const data = getUserLocalStorage();
    if (data) {
      setUser(data.user);
      setToken(data.token);
      setLanguage(data.language);
    }
  }, []);

  const authenticate = async (email: string, password: string) => {
    try {
      const login = await loginApi(email, password);
      setUser(login.data);
      setToken(login.token);
      setUserLocalStorage(login);
      setLoginError(false);
    } catch (err: any) {
      setLoginError(true);
      throw new Error(err?.response?.data?.message);
    }
  };

  const getUser = async (userID: number) => {
    setUserLoadingData(true);
    try {
      const getUser = await getUserApi(userID);
      setUser(getUser?.data);
      setSiteConfig(getUser?.config);
      setAlerts(getUser?.alerts);
      localStorage.setItem("@treasurebox_user", JSON.stringify(getUser.data));
      localStorage.setItem(
        "@treasurebox_config",
        JSON.stringify(getUser.config)
      );
      localStorage.setItem(
        "@treasurebox_alerts",
        JSON.stringify(getUser?.alerts)
      );
      setLoginError(false);
      return getUser.data;
    } catch (err: any) {
      setLoginError(true);
    } finally {
      setUserLoadingData(false);
    }
  };

  const forgotPassword = async (email: string) => {
    try {
      await forgotPasswordApi(email);
      return "success";
    } catch (err: any) {
      return "failed";
    }
  };

  const forgotPasswordVerifyCode = async (email: string, code: string) => {
    try {
      await verifyCodeForgotPasswordApi(email, code);
      return "success";
    } catch (err: any) {
      return "failed";
    }
  };

  const changePassword = async (
    email: string,
    code: string,
    newPassword: string
  ) => {
    try {
      await changePasswordApi(email, code, newPassword);
      return "success";
    } catch (err: any) {
      return "failed";
    }
  };

  const logout = async () => {
    setUser({} as User);
    setToken("");
    localStorage.removeItem("@treasurebox_user");
    localStorage.removeItem("@treasurebox_token");
    localStorage.removeItem("@treasureBox-menuOption");
  };

  const changeLanguage = (option: "english" | "portuguese") => {
    localStorage.setItem("@treasurebox_language", JSON.stringify(option));
    setLanguage(option);
  };

  const isAdmin = user?.user?.role === "admin";

  return (
    <AuthContext.Provider
      value={{
        isAdmin,
        user,
        token,
        loginError,
        language,
        siteConfig,
        getUser,
        changeLanguage,
        authenticate,
        logout,
        forgotPassword,
        changePassword,
        forgotPasswordVerifyCode,
        getUserLocalStorage,
        userLoadingData,
        alerts,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, AuthContext };

export default function useAuth() {
  return useContext(AuthContext);
}
