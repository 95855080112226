import { useEffect } from "react";
import {
  BoxContent,
  BreakpointBox,
  CircularLoading,
  FadeInContainer,
} from "../../../Atoms";
import { GenericInput } from "../../../Molecules";
import { Box, Button, Typography } from "@mui/material";
import { LoadingConfigs } from "../../../../../pages";
import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { useFormik } from "formik";

type PricesConfigProps = {
  orderTax: number;
  askProductPrice: number;
  offerPrice: number;
  plasticBubblePrice: number;
  reinforcedBoxPrice: number;
  removePackedPrice: number;
  takeNewPhotosPrice: number;
  stockPrice: number;
  daysFreeInStorage: number;
  defaultBoxWeight: number;
  daysFreeInStorageLojista: number;
  taxPriceNormal: number;
  taxPriceLojista: number;
};

interface Props {
  pricesConfig: PricesConfigProps;
  onSubmit(val: PricesConfigProps): void;
  loading: LoadingConfigs;
}

export function PricesConfig({ pricesConfig, onSubmit, loading }: Props) {
  const { isMobile, isSmalldesktop } = useBreakpoints();

  const formik = useFormik<PricesConfigProps>({
    initialValues: {
      orderTax: 0,
      askProductPrice: 0,
      offerPrice: 0,
      plasticBubblePrice: 0,
      reinforcedBoxPrice: 0,
      removePackedPrice: 0,
      takeNewPhotosPrice: 0,
      stockPrice: 0,
      daysFreeInStorage: 0,
      defaultBoxWeight: 0,
      daysFreeInStorageLojista: 0,
      taxPriceNormal: 0,
      taxPriceLojista: 0,
    },
    async onSubmit(values) {
      await onSubmit(values);
    },
  });

  useEffect(() => {
    if (pricesConfig) {
      formik.setFieldValue("orderTax", pricesConfig?.orderTax);
      formik.setFieldValue("askProductPrice", pricesConfig?.askProductPrice);
      formik.setFieldValue("offerPrice", pricesConfig?.offerPrice);
      formik.setFieldValue(
        "plasticBubblePrice",
        pricesConfig?.plasticBubblePrice
      );
      formik.setFieldValue(
        "reinforcedBoxPrice",
        pricesConfig?.reinforcedBoxPrice
      );
      formik.setFieldValue(
        "removePackedPrice",
        pricesConfig?.removePackedPrice
      );
      formik.setFieldValue(
        "takeNewPhotosPrice",
        pricesConfig?.takeNewPhotosPrice
      );
      formik.setFieldValue("stockPrice", pricesConfig?.stockPrice);
      formik.setFieldValue(
        "daysFreeInStorage",
        pricesConfig?.daysFreeInStorage
      );
      formik.setFieldValue("defaultBoxWeight", pricesConfig?.defaultBoxWeight);
      formik.setFieldValue(
        "daysFreeInStorageLojista",
        pricesConfig?.daysFreeInStorageLojista
      );
      formik.setFieldValue("taxPriceNormal", pricesConfig?.taxPriceNormal);
      formik.setFieldValue("taxPriceLojista", pricesConfig?.taxPriceLojista);
    }
    // eslint-disable-next-line
  }, [pricesConfig]);

  const regexNumber = new RegExp("^[0-9]*$");

  return (
    <BoxContent
      config={{ mb: 1, width: isMobile || isSmalldesktop ? "100% " : "50%" }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent={"space-between"}
        height={"100%"}
        width={"100%"}
      >
        {loading.prices ? (
          <CircularLoading text="Atualizando" />
        ) : (
          <FadeInContainer>
            <Typography variant="h1" mb={2}>
              Prices
            </Typography>
            <Box
              display="flex"
              flexWrap={"wrap"}
              width={"100%"}
              gap={1}
              justifyContent={"center"}
            >
              <BreakpointBox>
                <GenericInput
                  inputName={"orderTax"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.orderTax}
                  placeholder={"Orders in %"}
                  hasIcon={false}
                />
                <GenericInput
                  inputName={"askProductPrice"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.askProductPrice}
                  placeholder={"Ask price"}
                  hasIcon={false}
                />
                <GenericInput
                  inputName={"offerPrice"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.offerPrice}
                  placeholder={"Offer price"}
                  hasIcon={false}
                />
              </BreakpointBox>
              <BreakpointBox>
                <GenericInput
                  inputName={"plasticBubblePrice"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.plasticBubblePrice}
                  placeholder={"Plastic bubble"}
                  hasIcon={false}
                />
                <GenericInput
                  inputName={"reinforcedBoxPrice"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.reinforcedBoxPrice}
                  placeholder={"Reinforced box Price"}
                  hasIcon={false}
                />
              </BreakpointBox>
              <BreakpointBox>
                <GenericInput
                  inputName={"removePackedPrice"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.removePackedPrice}
                  placeholder={"Remove packed price"}
                  hasIcon={false}
                />
                <GenericInput
                  inputName={"takeNewPhotosPrice"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.takeNewPhotosPrice}
                  placeholder={"Take new photos price"}
                  hasIcon={false}
                />
                <GenericInput
                  inputName={"stockPrice"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.stockPrice}
                  placeholder={"Stocking price"}
                  hasIcon={false}
                />
              </BreakpointBox>

              <BreakpointBox>
                <GenericInput
                  inputName={"daysFreeInStorage"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.daysFreeInStorage}
                  placeholder={"Max days free in stock"}
                  hasIcon={false}
                />{" "}
                <GenericInput
                  inputName={"daysFreeInStorageLojista"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.daysFreeInStorageLojista}
                  placeholder={"Max days free in stock lojista"}
                  hasIcon={false}
                />
              </BreakpointBox>

              <BreakpointBox>
                <GenericInput
                  inputName={"defaultBoxWeight"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.defaultBoxWeight}
                  placeholder={"Default box weight in g"}
                  hasIcon={false}
                />
                <GenericInput
                  inputName={"taxPriceNormal"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.taxPriceNormal}
                  placeholder={"Tax price"}
                  hasIcon={false}
                />

                <GenericInput
                  inputName={"taxPriceLojista"}
                  onChange={(value, name) => {
                    if (value.match(regexNumber)) {
                      formik.setFieldValue(name, Number(value));
                    }
                  }}
                  value={formik.values.taxPriceLojista}
                  placeholder={"Tax price lojista"}
                  hasIcon={false}
                />
              </BreakpointBox>
            </Box>
          </FadeInContainer>
        )}

        <Button
          fullWidth
          variant="contained"
          onClick={() => formik.submitForm()}
          sx={{ mt: 1 }}
        >
          Update
        </Button>
      </Box>
    </BoxContent>
  );
}
