import {
  CardActions,
  CardContent,
  Card,
  Box,
  Chip,
  Typography,
} from "@mui/material";
import {
  DataText,
  Button,
  CustomToolTip,
  NoData,
  MailNotification,
  CircularLoading,
} from "../../Atoms";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import useAuth from "../../../../hooks/context/useAuth";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { UseDateTime } from "../../../../hooks/useDateTime";
import { useCommonData } from "../../../../hooks/useCommonData";
import { amber, blue, orange, teal } from "@mui/material/colors";

interface Props {
  data: IOrderToBuy[];
  onClickOrder(val: IOrderToBuy): void;
  isLoading?: boolean;
}

export function OrderToBuyCards({ data, onClickOrder, isLoading }: Props) {
  const { labelStatus, orderTypeStrings, auctionSteps } = useCommonData();
  const { formatedDate } = UseDateTime();
  const { isSmalldesktop, isMobile, mediumDesktop, semiSmalldesktop } =
    useBreakpoints();
  const { JPYmoney } = useJPYmoney();
  const { user } = useAuth();

  const titleWidth = 100;

  const hasNewMessage = (order: IOrderToBuy) => {
    const messages = order?.WarningMessage;
    if (!messages) return false;
    if (messages?.length === 0) return false;
    if (!messages[0]?.read && user?.user?.id !== messages[0]?.usersId) {
      if (user?.user?.role === messages[0]?.type) {
        return false;
      }
      return true;
    }
    return false;
  };

  const breakpoints = () => {
    if (isMobile) return "100%";
    if (isSmalldesktop) return "48.5%";
    if (semiSmalldesktop) return "32%";
    if (mediumDesktop) return "23.9%";
    return "19%";
  };

  const cardColor = (orderType: OrderType) => {
    const colors = {
      orderToBuy: blue[50],
      askQuestion: amber[50],
      bid: teal[50],
      auction: orange[300],
    };
    return colors[orderType as keyof typeof colors];
  };

  const translateStatus = (orderType: OrderType) => {
    const status = {
      orderToBuy: orderTypeStrings.orderToBuy,
      askQuestion: orderTypeStrings.askQuestion,
      bid: orderTypeStrings.bid,
      auction: orderTypeStrings.auction,
    };
    return status[orderType as keyof typeof status];
  };

  const getSuiteID = (item: any) => {
    if (item?.Users?.suite) return `${item?.Users?.suite[0]?.id}`;
    return "Não encontrado";
  };

  return (
    <Box
      width={"auto"}
      display="flex"
      flexWrap={"wrap"}
      rowGap={1.5}
      columnGap={1.5}
      justifyContent={"flex-start"}
    >
      {isLoading ? (
        <CircularLoading />
      ) : data && data?.length > 0 ? (
        data?.map((item, index) => (
          <Card
            sx={{
              width: breakpoints(),
              minWidth: "245px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              // bgcolor: cardColor(item.orderType),
              border: `2px solid ${cardColor(item?.orderType)}`,
            }}
            key={index}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                p={1}
                variant="h2"
                fontWeight={500}
                sx={{
                  whiteSpace: "nowrap",
                  bgcolor: cardColor(item?.orderType),
                  borderRadius: "0 0 5px 0",
                }}
              >
                {translateStatus(item.orderType)}
              </Typography>
              <Box sx={{ background: "#FFFFFF", height: 35, width: "100%" }} />
            </Box>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                  justifyContent: "center",
                  rowGap: 0.5,
                }}
              >
                <DataText
                  title={"Created at:"}
                  text={formatedDate(item?.registerDate)}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"ID:"}
                  text={item?.id}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Suite:"}
                  text={getSuiteID(item)}
                  titleProps={{ width: titleWidth }}
                />
                <CustomToolTip text={item?.Users?.user?.name}>
                  <DataText
                    title={"Customer:"}
                    text={`${item?.Users?.user?.name}`}
                    titleProps={{ width: titleWidth }}
                    textProps={{ overflow: "hidden", height: 19 }}
                  />
                </CustomToolTip>
                {/* <CustomToolTip text={item.title}>
                  <Box>
                    <DataText
                      title={"Title:"}
                      text={`${item?.title}`}
                      titleProps={{ width: titleWidth }}
                    />
                  </Box>
                </CustomToolTip> */}
                <DataText
                  title={
                    item.orderType === "auction" ? "Final price" : "Price:"
                  }
                  text={
                    item.orderType === "auction"
                      ? `${JPYmoney(item?.totalAuctionPrice)}`
                      : `${JPYmoney(item?.totalPrice)}`
                  }
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Credit used:"}
                  text={
                    item?.userCreditUsed > 0
                      ? `- ${JPYmoney(item?.userCreditUsed)}`
                      : `${JPYmoney(item?.userCreditUsed)}`
                  }
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={
                    item.orderType === "auction" ? "Max bid" : "Remaning $:"
                  }
                  text={
                    item.orderType === "auction"
                      ? `${JPYmoney(item?.price)}`
                      : `${JPYmoney(item?.totalPrice)}`
                  }
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Pay method:"}
                  text={`${item?.paymentChoice?.toUpperCase()}`}
                  titleProps={{ width: titleWidth }}
                />
                <DataText
                  title={"Status:"}
                  text={
                    item.orderType === "auction"
                      ? `${
                          auctionSteps[
                            item.auctionStatus as keyof typeof auctionSteps
                          ]
                        } ${
                          item?.auctionStatus === 4
                            ? JPYmoney(item?.totalAuctionPrice)
                            : ""
                        }`
                      : `${
                          labelStatus[item?.status as keyof typeof labelStatus]
                            ?.label
                        }`.toUpperCase()
                  }
                  titleProps={{ width: titleWidth }}
                  textProps={{
                    sx: { wordBreak: "break-word", maxWidth: 180 },
                  }}
                />
              </Box>
            </CardContent>
            <CardActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                config={{
                  size: "small",
                  fullWidth: false,
                  onClick: () => onClickOrder(item),
                }}
                style={{ color: "white" }}
              >
                {labelStatus.button.label}
              </Button>
              <MailNotification hasNewMessage={hasNewMessage(item)} disabled />
              <Chip
                label={
                  labelStatus[item?.status as keyof typeof labelStatus]?.label
                }
                style={{
                  background:
                    labelStatus[item?.status as keyof typeof labelStatus]
                      ?.color,
                  color: "white",
                  fontWeight: 500,
                }}
              />
            </CardActions>
          </Card>
        ))
      ) : (
        <NoData text="No data" />
      )}
    </Box>
  );
}
