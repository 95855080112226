import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import {
  BoxWithTitle,
  GenericInput,
} from "../../../components/AtomicDesign/Molecules";
import useAuth from "../../../hooks/context/useAuth";
import { useTranslate } from "../../../hooks/useTranslate";
import { useEffect, useState } from "react";
import {
  createAlertService,
  deleteAlertService,
  getAlertsAdminService,
  updateAlertService,
} from "../../../services/siteConfigService";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import { CircularLoading } from "../../../components/AtomicDesign/Atoms";

const colors = [
  {
    color: "#ffecb3",
    name: "Amber",
  },
  {
    color: "#80cbc4",
    name: "Teal",
  },
  {
    color: "#b3e5fc",
    name: "Blue",
  },
  {
    color: "#cfd8dc",
    name: "Grey",
  },
];

const textColor = [
  {
    color: "#FFFFFF",
    name: "White",
  },
  {
    color: "#000000",
    name: "Black",
  },
  {
    color: "#cfd8dc",
    name: "Grey",
  },
];

export function AlertsConfig() {
  const { strings } = useTranslate();
  const { language } = useAuth();
  const [oldData, setOldData] = useState([] as AlertsConfigProps[]);
  const [newAlertConfig, setNewAlertConfig] = useState({
    active: true,
    portuguese: "",
    english: "",
    backgroundColor: "#ffecb3",
    textColor: "#000000",
    messageColor: "",
  } as NewAlertsConfigProps);
  const [hiddenMoreAlerts, setHiddenMoreAlerts] = useState(true);
  const [loading, setLoading] = useState(false);

  const getAlerts = async () => {
    setLoading(true);
    try {
      const response = await getAlertsAdminService();
      setOldData(response);
    } catch {
      setOldData([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAlerts();
  }, []);

  const createAlert = async () => {
    setLoading(true);
    try {
      const response = await createAlertService(newAlertConfig);
      setOldData(response);
    } catch {
      setOldData([]);
    } finally {
      setNewAlertConfig({
        active: true,
        portuguese: "",
        english: "",
        backgroundColor: "#ffecb3",
        textColor: "#000000",
        messageColor: "",
      });
      getAlerts();
    }
  };

  const changeAlertActive = async (data: any) => {
    setLoading(true);
    try {
      await updateAlertService(data);
    } catch {
    } finally {
      getAlerts();
    }
  };
  const deleteAlert = async (alertID: number) => {
    setLoading(true);
    try {
      await deleteAlertService(alertID);
    } catch {
    } finally {
      getAlerts();
    }
  };

  return (
    <BoxWithTitle
      title={strings.siteAlertsPage.title[language]}
      width="100%"
      childrenProps={{ height: "100%" }}
      padding={2}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={0.2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant="h1">Alertas atuais</Typography>
        {loading ? (
          <CircularLoading />
        ) : (
          <Box
            display={"flex"}
            flexDirection={"column"}
            width={"100%"}
            sx={{ overflow: hiddenMoreAlerts ? "hidden" : "visible" }}
            height={hiddenMoreAlerts ? 124 : "auto"}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              gap={0.2}
              width={"100%"}
            >
              {oldData?.length > 0 &&
                oldData?.map((alert, index) => (
                  <Box sx={{ width: "80%", display: "flex" }} key={index}>
                    <Box
                      sx={{
                        background: alert?.backgroundColor,
                        p: 1,
                        overflow: "hidden",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          color: alert?.textColor,
                          fontSize: 12,
                        }}
                      >
                        {language === "english"
                          ? alert?.english
                          : alert?.portuguese}
                      </Typography>
                    </Box>
                    <Box display={"flex"} gap={1} alignItems={"center"}>
                      <Checkbox
                        defaultChecked={alert?.active}
                        onChange={(_e, check) =>
                          changeAlertActive({ ...alert, active: check })
                        }
                      />
                      Ativo
                    </Box>
                    <Box display={"flex"} gap={1} alignItems={"center"} ml={1}>
                      <IconButton
                        onClick={() => deleteAlert(alert?.id)}
                        aria-label="delete"
                        sx={{ background: "red" }}
                      >
                        <DeleteIcon sx={{ fill: "#FFFFFF" }} />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        )}

        {oldData?.length > 3 && (
          <Box
            width={"80%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            mt={1}
            onClick={() => setHiddenMoreAlerts((old) => !old)}
            sx={{ cursor: "pointer" }}
          >
            <Divider sx={{ width: "100%" }} />
            <Typography fontWeight={500}>
              {hiddenMoreAlerts ? "mostrar mais alertas" : "esconder alertas"}
            </Typography>
            {hiddenMoreAlerts ? (
              <KeyboardArrowDownIcon sx={{ mt: -1 }} />
            ) : (
              <KeyboardArrowUpIcon sx={{ mt: -1 }} />
            )}
          </Box>
        )}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={0.2}
        justifyContent={"center"}
        alignItems={"center"}
        mt={2}
      >
        <Typography variant="h1">Prévia do alerta</Typography>

        <Typography variant="h2" sx={{ mt: 1 }}>
          Português
        </Typography>
        <Box
          sx={{
            background: newAlertConfig?.backgroundColor,
            p: 1,
            overflow: "hidden",
            width: "80%",
          }}
        >
          <Typography
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: newAlertConfig?.textColor,
              fontSize: 12,
            }}
          >
            {newAlertConfig?.portuguese}
          </Typography>
        </Box>
        <Typography variant="h2">Inglês</Typography>
        <Box
          sx={{
            background: newAlertConfig?.backgroundColor,
            p: 1,
            overflow: "hidden",
            width: "80%",
          }}
        >
          <Typography
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: newAlertConfig?.textColor,
              fontSize: 12,
            }}
          >
            {newAlertConfig?.english}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            border: "1px solid lightgray",
            borderRadius: 5,
            width: "79%",
            mt: 1,
            p: 2,
          }}
        >
          <Typography variant="h1" mt={2}>
            Novo campo
          </Typography>
          <GenericInput
            inputName={"portuguese"}
            value={newAlertConfig.portuguese}
            onChange={(value) => {
              if (value.length < 1000) {
                setNewAlertConfig((old) => ({
                  ...old,
                  portuguese: value,
                }));
              }
            }}
            placeholder={"Português"}
            hasIcon={false}
          />
          <Typography textAlign={"end"}>
            {999 - newAlertConfig?.portuguese?.length} caracteres restantes
          </Typography>
          <GenericInput
            value={newAlertConfig.english}
            inputName={"english"}
            onChange={(value) => {
              if (value.length < 1000) {
                setNewAlertConfig((old) => ({
                  ...old,
                  english: value,
                }));
              }
            }}
            placeholder={"English"}
            hasIcon={false}
          />
          <Typography textAlign={"end"}>
            {999 - newAlertConfig?.english?.length} caracteres restantes
          </Typography>
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box
              display={"flex"}
              flexWrap={"wrap"}
              gap={1}
              alignItems={"center"}
            >
              <Checkbox
                defaultChecked
                onChange={(_e, check) =>
                  setNewAlertConfig((old) => ({
                    ...old,
                    active: check,
                  }))
                }
              />
              Ativo
            </Box>
            <Typography mt={1}>Cor de fundo do alerta</Typography>
            <Box display={"flex"} flexWrap={"wrap"} gap={1}>
              {colors?.map(({ color, name }, index) => (
                <Button
                  key={index}
                  variant="contained"
                  sx={{
                    background: color,
                    boder:
                      color === newAlertConfig.backgroundColor
                        ? "3px solid green"
                        : "none",
                    "&:hover": { background: color },
                  }}
                  onClick={() =>
                    setNewAlertConfig((old) => ({
                      ...old,
                      backgroundColor: color,
                    }))
                  }
                >
                  {name} {}
                </Button>
              ))}
            </Box>

            <Typography mt={1}>Cor do texto do alerta</Typography>
            <Box display={"flex"} flexWrap={"wrap"} gap={1}>
              {textColor?.map(({ color, name }, index) => (
                <Button
                  key={index}
                  variant="contained"
                  sx={{
                    color: name === "White" ? "black" : "white",
                    background: color,
                    boder:
                      color === newAlertConfig.textColor
                        ? "3px solid green"
                        : "none",
                    "&:hover": { background: color },
                  }}
                  onClick={() =>
                    setNewAlertConfig((old) => ({
                      ...old,
                      textColor: color,
                    }))
                  }
                >
                  {name}
                </Button>
              ))}
            </Box>
          </Box>

          <Button
            sx={{ mt: 2 }}
            onClick={() => createAlert()}
            variant="contained"
          >
            Adicionar alerta
          </Button>
        </Box>
      </Box>
    </BoxWithTitle>
  );
}
