import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import LogoutIcon from "@mui/icons-material/Logout";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import RedeemOutlinedIcon from "@mui/icons-material/RedeemOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import SdCardAlertIcon from "@mui/icons-material/SdCardAlert";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../../hooks/context/useAuth";
import { colors } from "../../../../theme/colors";
import { useTranslate } from "../../../../hooks/useTranslate";
import SettingsSuggestIcon from "@mui/icons-material/SettingsSuggest";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import useOrdersAdmin from "../../../../hooks/context/useOrdersAdmin";
import useProductsAdmin from "../../../../hooks/context/useProductsAdmin";
import useShippments from "../../../../hooks/context/useShippments";
import { red } from "@mui/material/colors";

export function MenuDrawer({
  drawerStatus,
}: {
  drawerStatus(val: boolean): void;
}) {
  const { JPYmoney } = useJPYmoney();
  const [optionSelected, setOptionSelected] = useState(0);
  const navigate = useNavigate();
  const { isMobile, isSmalldesktop } = useBreakpoints();
  const { logout, user, language } = useAuth();
  const { strings } = useTranslate();
  const [open, setOpen] = useState(true);
  const { menuOrdersCount, menuAuctionCount } = useOrdersAdmin();
  const { menuProductsCount } = useProductsAdmin();
  const { menuShipCount } = useShippments();

  const paths = [
    {
      name: strings.drawerMenu.dashboard[language],
      path: "/treasurebox-app",
      icon: <SpaceDashboardIcon />,
      menuTitle: "Main",
      count: undefined,
    },
    {
      name: strings.drawerMenu.order[language],
      path: "/treasurebox-app/register-order",
      icon: <ShoppingCartIcon />,
      menuTitle: "Shopping",
    },
    {
      name: "Suite",
      path: "/treasurebox-app/suite",
      icon: <CardGiftcardIcon />,
    },
    {
      name: strings.drawerMenu.shippments[language],
      path: "/treasurebox-app/shippments",
      icon: <LocalShippingIcon />,
    },
    {
      name: strings.drawerMenu.credits[language],
      path: "/treasurebox-app/credits",
      icon: <AttachMoneyIcon />,
      menuTitle: "User info",
    },
    {
      name: strings.drawerMenu.myInfo[language],
      path: "/treasurebox-app/my-info",
      icon: <AssignmentIndIcon />,
    },
  ];

  const pathsAdmin = [
    {
      name: strings.drawerMenu.dashboard[language],
      path: "/treasurebox-app/admin",
      icon: <SpaceDashboardIcon />,
      menuTitle: "Main",
    },
    {
      name: strings.drawerMenu.order[language],
      path: "/treasurebox-app/admin/orders",
      icon: <ShoppingCartIcon />,
      menuTitle: "Shopping",
      count: menuOrdersCount,
    },
    {
      name: strings.drawerMenu.auctions[language],
      path: "/treasurebox-app/admin/auctions",
      icon: <CurrencyExchangeIcon />,
      count: menuAuctionCount,
    },
    {
      name: strings.drawerMenu.products[language],
      path: "/treasurebox-app/admin/products",
      icon: <CardGiftcardIcon />,
      count: menuProductsCount,
    },
    {
      name: strings.drawerMenu.shippments[language],
      path: "/treasurebox-app/admin/shippments",
      icon: <LocalShippingIcon />,
      count: menuShipCount,
    },
    {
      name: strings.drawerMenu.credits[language],
      path: "/treasurebox-app/admin/credits-admin",
      icon: <AttachMoneyIcon />,
      menuTitle: "Users info",
    },
    {
      name: strings.drawerMenu.users[language],
      path: "/treasurebox-app/admin/users",
      icon: <AssignmentIndIcon />,
    },
    {
      name: strings.drawerMenu.systemConfig[language],
      path: "/treasurebox-app/admin/system-config",
      icon: <SettingsSuggestIcon />,
      menuTitle: "System",
    },
    {
      name: strings.drawerMenu.freteConfig[language],
      path: "/treasurebox-app/admin/fretes-config",
      icon: <DirectionsBoatIcon />,
    },
    {
      name: strings.drawerMenu.alertsConfig[language],
      path: "/treasurebox-app/admin/alerts-config",
      icon: <SdCardAlertIcon />,
    },
  ];

  useEffect(() => {
    if (isMobile) {
      setOpen(false);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1000) {
        setOpen(false);
      } else {
        setOpen(true);
      }
    });

    const getMenuOption = localStorage.getItem("@treasureBox-menuOption");
    if (getMenuOption) {
      setOptionSelected(Number(getMenuOption));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    drawerStatus(open);

    // eslint-disable-next-line
  }, [open]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const filterRoutesByUser = (role: "client" | "admin") => {
    if (role === "client") return paths;
    if (role === "admin") return pathsAdmin;
    return paths;
  };

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Box>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          sx={{
            width: 240,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: 240,
              boxSizing: "border-box",
            },
          }}
          variant={isMobile || isSmalldesktop ? "temporary" : "persistent"}
          anchor="left"
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          open={open}
        >
          <Box
            onKeyDown={handleDrawerClose}
            display={"flex"}
            justifyContent={"space-between"}
            height={"72px"}
          >
            <Button
              variant="text"
              onClick={() => {
                navigate("/");
                logout();
              }}
            >
              <LogoutIcon style={{ marginRight: 3, color: "red" }} /> Logout
            </Button>
            <IconButton onClick={handleDrawerClose}>
              {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>
          {/* <Divider /> */}
          <Box p={1}>
            <Box
              bgcolor={colors.blue[900]}
              borderRadius={1}
              height={150}
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              lineHeight={2}
            >
              <Typography
                color={colors.white[500]}
                fontSize={"20px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <RedeemOutlinedIcon
                  style={{ fill: "#FFF", fontSize: 30, marginRight: 15 }}
                />
                Suíte {user?.suite?.length > 0 ? user?.suite[0]?.id : 0}
              </Typography>
              <Typography color={colors.white[500]} variant="h1">
                {user?.user?.name}
              </Typography>
            </Box>
          </Box>

          {user?.user?.role === "client" && (
            <>
              <Divider />
              <Box p={1}>
                <Box
                  bgcolor={colors.blue[900]}
                  borderRadius={1}
                  height={50}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  lineHeight={2}
                  mb={-1.8}
                >
                  <Typography
                    color={colors.white[500]}
                    fontSize={"20px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    {JPYmoney(Number(user?.money))}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          <List>
            {filterRoutesByUser(user?.user?.role).map((item, index) => (
              <ListItem
                key={index}
                disablePadding
                onClick={() => {
                  setOptionSelected(index);
                  navigate(item.path);
                  localStorage.setItem(
                    "@treasureBox-menuOption",
                    String(index)
                  );
                  if (isMobile) {
                    handleDrawerClose();
                  }
                }}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  overflowX: "hidden",
                }}
              >
                {item.menuTitle && (
                  <>
                    <Divider
                      sx={{
                        border: "0.5 solid lightgray",
                        mt: 1,

                        width: "95% ",
                      }}
                    />
                    <Typography width={"100%"} ml={1} mb={1}>
                      {item.menuTitle}
                    </Typography>
                  </>
                )}
                <ListItemButton
                  sx={{
                    background:
                      optionSelected === index
                        ? colors.blue[900]
                        : "transparent",
                    width: "100%",
                  }}
                >
                  <ListItemIcon
                    sx={{
                      color:
                        optionSelected === index
                          ? colors.white[500]
                          : "inherit",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color:
                        optionSelected === index
                          ? colors.white[500]
                          : "inherit",
                    }}
                    primary={item.name}
                  />
                  {item?.count && item?.count > 0 && (
                    <span
                      style={{
                        background: red[500],
                        borderRadius: "50%",
                        width: 25,
                        height: 25,
                        textAlign: "center",
                        alignContent: "center",
                        color: "#FFFFFF",
                      }}
                    >
                      {item?.count}
                    </span>
                  )}
                  {optionSelected === index && (
                    <PlayArrowIcon
                      sx={{
                        fill: colors.white[500],
                        fontSize: 30,
                        rotate: "58deg",
                        marginRight: -3.5,
                      }}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </SwipeableDrawer>
      </Box>
    </Box>
  );
}
