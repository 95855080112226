import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { FormikProps, useFormik } from "formik";
import { GenericInput } from "../GenericInput";
import * as yup from "yup";
import { useCommonData } from "../../../../hooks/useCommonData";
import { BreakpointBox, CustomToolTip } from "../../Atoms";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { blue, green, red, teal } from "@mui/material/colors";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import useAuth from "../../../../hooks/context/useAuth";
import { useState } from "react";
import { updateLinkAdmin } from "../../../../services/orderToBuyService";
import useToast from "../../../../hooks/context/useToast";
import { ProductPreview } from "../ProductPreview";

interface Props {
  formik: FormikProps<ICreateOrderToBuy>;
  canEdit: boolean;
  multipleAdd: boolean;
  updateOrder: () => void;
}

const validationSchema = yup.object({
  link: yup.string().required(),
  price: yup.number().min(1).required(),
  quantity: yup.number().min(1).required(),
  title: yup.string().required(),
  orderToBuyId: yup.number().optional(),
  status: yup.string().optional(),
});

export function LinksOrderToBuy({
  formik,
  canEdit,
  multipleAdd,
  updateOrder,
}: Props) {
  const [updateLinkLoading, setUpdateLinkLoading] = useState<{
    loading: boolean;
    index: number | null;
  }>({ loading: false, index: null });

  const { setOpen, setToastData } = useToast();
  const { orderModal, linkStrings, erros, linkWarnings } = useCommonData();
  const { isMobile } = useBreakpoints();
  const { isAdmin } = useAuth();

  const linksFormik = useFormik<LinksOrderToBuy>({
    initialValues: {
      link: "",
      price: 0,
      quantity: 1,
      title: "",
      orderToBuyId: 0,
      status: "ordered",
      id: 0,
    },
    validationSchema,
    async onSubmit(values: LinksOrderToBuy) {
      if (formik?.values?.links?.length > 0) {
        formik?.setFieldValue("links", [
          ...formik?.values?.links,
          { ...values },
        ]);
      } else {
        formik?.setFieldValue("links", [{ ...values }]);
      }
      linksFormik?.resetForm();
    },
  });

  const removeLink = (link: string) => {
    formik?.setFieldValue(
      "links",
      formik.values.links?.filter((item) => item?.link !== link)
    );
  };

  const status = (status: string) => {
    switch (status) {
      case "ordered":
        return { text: linkStrings.ordered, color: blue[200] };
      case "bought":
        return { text: linkStrings.bought, color: teal[300] };
      case "canceled":
        return { text: linkStrings.canceled, color: red[300] };
      case "arrived":
        return { text: linkStrings.arrived, color: teal[700] };
      default:
        return { text: "", color: "" };
    }
  };

  const updateLinkStatus = async (
    index: number,
    status: string,
    linkID: number
  ) => {
    setUpdateLinkLoading({ loading: true, index });
    try {
      await updateLinkAdmin({ status, linkID });
      updateOrder();
    } catch (error) {
      setToastData({
        anchor: { vertical: "top", horizontal: "center" },
        duration: 5000,
        type: "error",
        message: erros.errorToastGeneric,
      });
      setOpen(true);
    } finally {
      setUpdateLinkLoading({ loading: false, index: null });
    }
  };

  return (
    <Box width={"100%"}>
      {!canEdit && (
        <Box
          display={"flex"}
          flexDirection={"column"}
          sx={{
            border: "1px solid lightgrey",
            borderRadius: "5px",
            p: 1,
            mt: 1,
          }}
        >
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => linksFormik.setFieldValue(name, value)}
            error={
              linksFormik?.touched?.title && linksFormik?.errors?.title
                ? true
                : false
            }
            errorMessage={
              linksFormik?.touched?.title ? linksFormik?.errors?.title : ""
            }
            value={linksFormik?.values?.title}
            placeholder={orderModal.title}
            inputName="title"
          />
          <GenericInput
            hasIcon={false}
            onChange={(value, name) => linksFormik.setFieldValue(name, value)}
            error={
              linksFormik?.touched?.link && linksFormik?.errors?.link
                ? true
                : false
            }
            errorMessage={
              linksFormik?.touched?.link ? linksFormik?.errors?.link : ""
            }
            value={linksFormik?.values?.link}
            placeholder={orderModal.textLink}
            inputName="link"
          />
          <BreakpointBox>
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                linksFormik.setFieldValue(name, Number(value))
              }
              error={
                linksFormik?.touched?.quantity && linksFormik?.errors?.quantity
                  ? true
                  : false
              }
              errorMessage={
                linksFormik?.touched?.quantity
                  ? linksFormik?.errors?.quantity
                  : ""
              }
              value={linksFormik?.values?.quantity}
              placeholder={orderModal?.quantity}
              inputName="quantity"
              inputType="number"
            />
            <GenericInput
              hasIcon={false}
              onChange={(value, name) =>
                linksFormik.setFieldValue(name, Number(value))
              }
              error={
                linksFormik?.touched?.price && linksFormik?.errors?.price
                  ? true
                  : false
              }
              errorMessage={
                linksFormik?.touched?.price ? linksFormik?.errors?.price : ""
              }
              value={linksFormik?.values?.price}
              placeholder={orderModal?.price}
              inputName="price"
              inputType="number"
            />
          </BreakpointBox>
          <Button
            onClick={() => {
              if (multipleAdd) {
                linksFormik?.submitForm();
              }
              if (!multipleAdd && formik?.values?.links?.length < 1) {
                linksFormik?.submitForm();
              }
            }}
            variant="contained"
          >
            Add link
          </Button>
        </Box>
      )}
      <Box display={"flex"} flexWrap={"wrap"} gap={1} mt={1}>
        {formik?.values?.links?.map((link, index) => (
          <Box width={isMobile ? "100%" : "49%"}>
            <Box
              sx={{
                bgcolor: link?.arrivedProducts
                  ? status("arrived").color
                  : status(link?.status).color,
                borderRadius: "5px 5px 0px 0px",
                p: 1,
                color: "white",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Typography fontSize={13}>
                {link?.arrivedProducts
                  ? status("arrived").text
                  : status(link?.status).text}
                {link?.arrivedProductsId &&
                  ` - Product n° ${link?.arrivedProductsId}`}
              </Typography>
              <ProductPreview product={link?.arrivedProducts} />
            </Box>
            <Box
              key={index}
              sx={{
                border: `1px solid ${status(link?.status).color}`,
                borderRadius: "0px 0px 5px 5px",
                p: 1,
              }}
            >
              {updateLinkLoading.loading &&
              updateLinkLoading.index === index ? (
                <CircularProgress />
              ) : (
                <Box width={"100%"} display={"flex"} flexDirection={"column"}>
                  <Box display={"flex"} justifyContent={"space-between"}>
                    <Box
                      width={"100%"}
                      display={"flex"}
                      flexDirection={"column"}
                    >
                      <Typography sx={{ fontWeight: 500 }}>
                        {orderModal.productName}
                      </Typography>
                      <CustomToolTip text={link?.title}>
                        <Typography sx={{ overflow: "hidden", maxHeight: 22 }}>
                          {link?.title}
                        </Typography>
                      </CustomToolTip>
                    </Box>
                    {!canEdit && (
                      <IconButton
                        aria-label="delete"
                        onClick={() => removeLink(link?.link)}
                        sx={{
                          bgcolor: "red",
                          width: 40,
                          height: 40,
                          zIndex: 12651651651561561,
                          "&:hover": { bgcolor: "pink" },
                        }}
                        disabled={canEdit}
                      >
                        <DeleteIcon sx={{ fill: "white" }} />
                      </IconButton>
                    )}
                  </Box>
                  <Typography sx={{ fontWeight: 500 }} mt={1}>
                    Link id {link?.id}
                  </Typography>
                  <Box display={"flex"} gap={2} mb={1} mt={1}>
                    <Button
                      onClick={async () =>
                        await navigator.clipboard.writeText(link?.link)
                      }
                      sx={{
                        bgcolor: blue[400],
                        "&:hover": { bgcolor: blue[500] },
                      }}
                      variant="contained"
                    >
                      Copy <FileCopyOutlinedIcon sx={{ ml: 1 }} />
                    </Button>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: teal[300],
                        "&:hover": { bgcolor: teal[500] },
                      }}
                    >
                      <a
                        href={link?.link}
                        style={{ textDecoration: "none", color: "white" }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Open <OpenInNewIcon sx={{ ml: 1, mb: -0.7 }} />
                      </a>
                    </Button>
                  </Box>
                  <Box display={"flex"} gap={2}>
                    <Box>
                      <Typography sx={{ fontWeight: 500 }}>
                        {orderModal.quantity}
                      </Typography>
                      <Typography>{link?.quantity}</Typography>
                    </Box>
                    <Box>
                      <Typography sx={{ fontWeight: 500 }}>
                        {orderModal.price}
                      </Typography>
                      <Typography>{link?.price}</Typography>
                    </Box>
                  </Box>

                  {isAdmin && (
                    <Box display={"flex"} gap={2} mb={1} mt={1}>
                      <Button
                        onClick={() =>
                          updateLinkStatus(index, "bought", link?.id)
                        }
                        sx={{
                          bgcolor: green[400],
                          "&:hover": { bgcolor: green[500] },
                        }}
                        variant="contained"
                      >
                        Comprado
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          bgcolor: red[300],
                          "&:hover": { bgcolor: red[500] },
                        }}
                        onClick={() =>
                          updateLinkStatus(index, "canceled", link?.id)
                        }
                      >
                        Cancelado
                      </Button>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      <Box gap={1} p={1}>
        <Typography fontSize={12}>1. {linkWarnings.step1}</Typography>
        <Typography fontSize={12}>2. {linkWarnings.step2}</Typography>
        <Typography fontSize={12}>3. {linkWarnings.step3}</Typography>
        <Typography fontSize={12}>4. {linkWarnings.step4}</Typography>
      </Box>
    </Box>
  );
}
