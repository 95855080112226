import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SelectProps,
} from "@mui/material";
import { amber } from "@mui/material/colors";

interface Props {
  value: string;
  onChange(value: string, name: string): void;
  error?: boolean;
  placeholder: string;
  width?: string;
  errorMessage?: string | any;
  name: string;
  options: {
    label: string;
    value: string | number;
    show?: boolean;
    disabled?: boolean;
  }[];
  disabled?: boolean;
  defaultValue?: any;
  formWidth?: string;
  variant?: "standard" | "outlined" | "filled";
  props?: SelectProps;
  emptyText?: string;
  hasWarning?: boolean;
}

export function GenericSelect({
  value,
  onChange,
  error,
  placeholder,
  width = "100%",
  formWidth = "95%",
  errorMessage,
  name,
  options,
  disabled = false,
  defaultValue = "",
  variant,
  props,
  emptyText,
  hasWarning = false,
}: Props) {
  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value, name);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: width,
      }}
    >
      <FormControl
        variant="standard"
        sx={{
          m: 1,

          width: formWidth,
          background: hasWarning ? amber["A100"] : "transparent",
          borderRadius: hasWarning ? 2 : 0,
        }}
      >
        <InputLabel
          id="demo-simple-select-standard-label"
          sx={{ p: hasWarning ? 1 : 0 }}
        >
          {placeholder}
        </InputLabel>
        <Select
          variant={variant}
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          sx={{ p: hasWarning ? 1 : 0 }}
          {...props}
          value={value}
          onChange={handleChange}
          defaultValue={defaultValue}
          defaultChecked
          label={placeholder}
          name={name}
          error={error}
          disabled={disabled}
        >
          <MenuItem value="" disabled>
            <em>{emptyText ? emptyText : "Select"}</em>
          </MenuItem>
          {options.map(({ label, value, disabled, show }, index) => {
            if (show) {
              return (
                <MenuItem value={value} key={index} disabled={disabled}>
                  <p style={{ whiteSpace: "pre-wrap" }}>{label}</p>
                </MenuItem>
              );
            } else if (show === undefined) {
              return (
                <MenuItem value={value} key={index}>
                  <p style={{ whiteSpace: "pre-wrap" }}>{label}</p>
                </MenuItem>
              );
            } else {
              return <></>;
            }
          })}
        </Select>
      </FormControl>
      <FormHelperText error>{errorMessage}</FormHelperText>
    </Box>
  );
}
