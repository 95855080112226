import { api } from "./api";

export const getSystemConfigService = async () => {
  const response2 = await api.get("/get-site-config");
  return response2.data;
};

export const updateSystemConfigService = async (data: any) => {
  const response2 = await api.post("/update-site-config", data);
  return response2.data;
};

export const updateThings = async () => {
  const response2 = await api.post("/update-things");
  return response2.data;
};

export const getAlertsAdminService = async () => {
  const response2 = await api.get("/get-alerts-admin");
  return response2.data;
};

export const getAlertsClientService = async () => {
  const response2 = await api.get("/get-alerts-client");
  return response2.data;
};

export const updateAlertService = async (data: any) => {
  const response2 = await api.post("/update-alert", data);
  return response2.data;
};

export const createAlertService = async (data: any) => {
  const response2 = await api.post("/create-alert", data);
  return response2.data;
};

export const deleteAlertService = async (alertID: number) => {
  const response2 = await api.post("/delete-alert", { alertID });
  return response2.data;
};
