import { BoxContent, CircularLoading, FadeInContainer } from "../../../Atoms";
import { Box, Button, Checkbox, Divider, Typography } from "@mui/material";
import { LoadingConfigs } from "../../../../../pages";

import { useBreakpoints } from "../../../../../hooks/useBreakpoins";
import { useEffect, useState } from "react";
type payment = {
  label: string;
  value: string;
  disabled: boolean;
  show: boolean;
};

interface Props {
  paymentOptions: payment[];
  onSubmit(val: any): void;
  loading: LoadingConfigs;
}

export function PaymentOptionsConfig({
  paymentOptions,
  onSubmit,
  loading,
}: Props) {
  const { isMobile } = useBreakpoints();
  const [newPaymentOptions, setNewPaymentOptions] = useState([] as payment[]);

  const submit = () => {
    const payload = {
      paymentOptions: [...newPaymentOptions],
    };
    onSubmit(payload);
  };

  const handleChange = (field: string, value: boolean, label: string) => {
    const old = newPaymentOptions;
    const findIndex = old.findIndex((pay) => pay.label === label);
    if (field === "show") {
      old[findIndex] = {
        label,
        disabled: old[findIndex].disabled,
        show: value,
        value: old[findIndex].value,
      };
    }
    if (field === "disabled") {
      old[findIndex] = {
        label,
        disabled: value,
        show: old[findIndex].show,
        value: old[findIndex].value,
      };
    }

    setNewPaymentOptions(old);
  };

  useEffect(() => {
    setNewPaymentOptions(paymentOptions);
  }, [paymentOptions]);

  return (
    <BoxContent>
      {loading.paymentOptions ? (
        <CircularLoading text="Atualizando" />
      ) : (
        <FadeInContainer>
          <Typography variant="h1" mb={2}>
            Opções de pagamento
          </Typography>
          <Box
            display="flex"
            flexWrap={"wrap"}
            flexDirection={"column"}
            width={"100%"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {newPaymentOptions?.map((option) => (
              <Box>
                <Box
                  display={"flex"}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={2}
                  width={"100%"}
                >
                  <Typography width={150}>Nome: {option?.label}</Typography>
                  <Typography>
                    Visualizar:{" "}
                    <Checkbox
                      defaultChecked={option.show}
                      onChange={(_e, check) =>
                        handleChange("show", check, option?.label)
                      }
                    />
                  </Typography>

                  <Typography>
                    Desabilitar click:{" "}
                    <Checkbox
                      defaultChecked={option.disabled}
                      onChange={(_e, check) =>
                        handleChange("disabled", check, option?.label)
                      }
                    />
                  </Typography>
                </Box>
                <Divider />
              </Box>
            ))}
          </Box>
        </FadeInContainer>
      )}

      <Button
        variant="contained"
        onClick={submit}
        sx={{ mt: 1, width: isMobile ? "100%" : "auto" }}
      >
        Update
      </Button>
    </BoxContent>
  );
}
