import { api } from "./api";

export const updateUserService = async (data: IUpdateUserDTO) => {
  const response2 = await api.post("/update-user", { data });
  return response2.data;
};

export const deleteUserService = async (userID: number) => {
  const response2 = await api.delete("/delete-user", { params: { userID } });
  return response2.data;
};

export const updateUserSuiteAdminService = async () => {
  const response2 = await api.post("/update-suite-users");
  return response2.data;
};
