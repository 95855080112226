import { CardContent, Typography, Card, Box } from "@mui/material";
import SportsEsportsIcon from "@mui/icons-material/SportsEsports";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CurrencyYuanIcon from "@mui/icons-material/CurrencyYuan";
import HelpIcon from "@mui/icons-material/Help";
import { CustomToolTip } from "../../Atoms";
import useAuth from "../../../../hooks/context/useAuth";

export type IIcon = "game" | "box" | "service" | "shipping";

interface Props {
  data: {
    text: string;
    icon: IIcon;
    color: string;
    value: number;
    showTooltip: boolean;
  }[];
}

export function ResumeBox({ data }: Props) {
  const { language } = useAuth();
  const icons = {
    game: <SportsEsportsIcon style={{ fill: "#FFF" }} />,
    credits: <CurrencyYuanIcon style={{ fill: "#FFF" }} />,
    service: <ContactPhoneIcon style={{ fill: "#FFF" }} />,
    shipping: <LocalShippingIcon style={{ fill: "#FFF" }} />,
  };

  const helperText = {
    portuguese:
      "As informações mostradas neste painel referem-se a pedidos que ainda não foram finalizados.",
    english:
      "The information displayed on this panel refers to orders that have not yet been completed.",
  };
  return (
    <Box
      width={"92.2%"}
      display="flex"
      flexWrap={"wrap"}
      rowGap={2}
      justifyContent={"space-between"}
    >
      {data.map((item, index) => (
        <Card sx={{ width: 340 }} key={index}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <Box
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Typography variant="h2" fontWeight={400} mb={1}>
                  {item.text}
                </Typography>
                {item.showTooltip && (
                  <CustomToolTip text={helperText[language]}>
                    <HelpIcon
                      sx={{ ml: 0.5, fontSize: 20, fill: "grey", mt: -1 }}
                    />
                  </CustomToolTip>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",

                  justifyContent: "flex-start",
                  width: "100%",
                }}
              >
                <Box
                  bgcolor={item.color}
                  width={40}
                  height={40}
                  p={1}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  mr={1}
                >
                  <Typography
                    sx={{ mr: 2 }}
                    color="text.secondary"
                    width={"100%"}
                    display={"flex"}
                    marginLeft={1.5}
                    alignItems={"center"}
                    variant="h1"
                  >
                    {icons[item.icon as keyof typeof icons]}
                  </Typography>
                </Box>
                <Typography
                  variant="h2"
                  fontWeight={400}
                  style={{ fontSize: "50px" }}
                >
                  {item.value}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
}
