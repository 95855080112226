import { Box, Divider, Switch, Typography } from "@mui/material";
import { useJPYmoney } from "../../../../hooks/useJPYmoney";
import { CustomToolTip } from "../ToolTip";
import HelpIcon from "@mui/icons-material/Help";

interface Props {
  title: string;
  price: number;
  hasSwitch?: boolean;
  switchValue?: boolean;
  onChangeSwitch?(val: boolean): void;
  disabled?: boolean;
  show?: boolean;
  toolTipText: string | undefined;
}

export function TitleMoneyText({
  title,
  price,
  hasSwitch = false,
  switchValue = false,
  disabled = false,
  onChangeSwitch,
  show = true,
  toolTipText,
}: Props) {
  const { JPYmoney } = useJPYmoney();
  return show ? (
    <>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        width={"100%"}
        mb={1}
        mt={1}
      >
        <Box display={"flex"} gap={0.5} alignItems={"center"}>
          <Typography>{title}</Typography>
          {toolTipText && (
            <CustomToolTip text={toolTipText}>
              <HelpIcon sx={{ fontSize: 20, fill: "grey" }} />
            </CustomToolTip>
          )}
        </Box>
        {hasSwitch && (
          <Switch
            disabled={disabled}
            checked={switchValue}
            size={"small"}
            color="warning"
            onChange={(_e, check) => onChangeSwitch && onChangeSwitch(check)}
          />
        )}
        <Typography>{JPYmoney(price)}</Typography>
      </Box>
      <Divider />
    </>
  ) : (
    <></>
  );
}
