import * as yup from "yup";
import { useTranslate } from "../../../../hooks/useTranslate";

export function Validation(language: "english" | "portuguese") {
  const { strings } = useTranslate();
  const validationSchema = yup.object({
    title: yup.string().required(strings.validationFields.title[language]),
    description: yup.string().required("desrição errada"),
    price: yup
      .number()
      .test((val) => {
        if (!val) {
          return false;
        }
        if (val <= 0) {
          return false;
        } else {
          return true;
        }
      })
      .required(strings.validationFields.price[language]),
    itemsQuantity: yup
      .number()
      .test((val) => {
        if (!val) {
          return false;
        }
        if (val <= 0) {
          return false;
        } else {
          return true;
        }
      })
      .required(strings.validationFields.itemQtd[language]),
    paymentChoice: yup
      .string()
      .required(strings.validationFields.payChoice[language]),
    serviceTax: yup.number().required("taxa errada"),
    subTotal: yup.number().required("total errado"),
    discount: yup.number().required("disconto errado"),
    userCreditUsed: yup.number().required("crediuto"),
    paymentTax: yup.number().required("taxapagametno"),
    totalPrice: yup.number().required("total preço"),
    status: yup.string().required("status"),
    userID: yup.number().required("userid"),
    termsConditions: yup
      .bool()
      .oneOf([true], strings.validationFields.termsConditions[language]),
    links: yup
      .array()
      .of(
        yup.object().shape({
          link: yup.string().required(),
          price: yup.number().required(),
          quantity: yup.number().required(),
          title: yup.string().required(),
          orderToBuyId: yup.number().optional(),
          status: yup.string().optional(),
        })
      )
      .optional(),
  });
  return { validationSchema };
}
