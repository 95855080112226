import step1 from "../../../../assets/images/ordernew/step1.png";
import step2 from "../../../../assets/images/ordernew/step2.png";
import step3 from "../../../../assets/images/ordernew/step3.png";
import step4 from "../../../../assets/images/ordernew/step4.png";
import step5 from "../../../../assets/images/ordernew/step5.png";
import step6 from "../../../../assets/images/ordernew/step6.png";
import step7 from "../../../../assets/images/ordernew/step7.png";
import step8 from "../../../../assets/images/ordernew/step8.png";
import step9 from "../../../../assets/images/ordernew/step9.png";
import celular from "../../../../assets/images/menina_com_celular.png";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useState } from "react";
import { FadeInContainer } from "../../../AtomicDesign/Atoms";
import { Box, IconButton, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import useAuth from "../../../../hooks/context/useAuth";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

export function HelpOrder() {
  const [activeIndex, setActiveIndex] = useState(0);
  const { language } = useAuth();
  const { isSmalldesktop } = useBreakpoints();
  const texts = [
    {
      image: step1,
      text: {
        portuguese: "Clique em nova ordem para abrir o menu de tipos de pedido",
        english: "Click on new order to open the order menu",
      },
    },
    {
      image: step2,
      text: {
        portuguese:
          "Somente a Ordem de Compra e Leilão possuem a alteração, pois elas vão gerar um produto quando chegarem em nosso estoque.",
        english:
          "Only the Purchase Order and Auction have the change, as they will generate a product when they arrive in our stock.",
      },
    },
    {
      image: step3,
      text: {
        portuguese:
          "Escolhemos a Ordem de Compra, agora você vai poder colocar o nome do produto, link do produto, quantidade (que por padrão colocamos 1) e o preço por unidade.",
        english:
          "We chose the Purchase Order, now you will be able to write the product name, product link, quantity (by default we set it to 1) and the price per unit.",
      },
    },
    {
      image: step4,
      text: {
        portuguese:
          "Após preenchido com as informações do produto que deseja comprar, clique em ADD LINK.",
        english:
          "After filling in the information about the product you wish to purchase, click on ADD LINK.",
      },
    },
    {
      image: step5,
      text: {
        portuguese:
          "Então um cartão com as informações que você preencheu será criado, você pode clicar em COPY ou OPEN para ver o link do produto inserido.",
        english:
          "Then a card with the information you filled in will be created, you can click COPY or OPEN to see the link of the product inserted.",
      },
    },
    {
      image: step6,
      text: {
        portuguese:
          "Você pode adicionar quantos produtos que quiser no pedido, basta preencher cada produto e clicar em ADD LINK para cada link inserido. Então, escolha a forma de pagamento e confirme as informações e depois vá até o final e clique em criar.",
        english:
          "You can add as many products as you want to the order, just fill in each product and click on ADD LINK for each link inserted. Then, choose the payment method and confirm the information and then go to the end and click on create.",
      },
    },
    {
      image: step7,
      text: {
        portuguese:
          "O pedido será criado, após abrir o pedido, você vai poder efetuar o pagamento conforme a opção escolhida anteriormente.",
        english:
          "The order will be created, after opening the order, you will be able to make the payment according to the option chosen previously.",
      },
    },
    {
      image: step8,
      text: {
        portuguese:
          "Após confirmação do pagamento, você pode acompanhar o andamento do pedido, cada link de produto que você criou vai possuir seu status.",
        english:
          "After payment confirmation, you can track the progress of your order, each product link you created will have its status.",
      },
    },
    {
      image: step9,
      text: {
        portuguese:
          "Quando o produto comprado chegar em nosso estoque, vamos cadastra-lo e o link do pedido que foi criado no começo, vai mudar e ficar marcado como entregue e você poderá visualizar o produto no menu SUÍTE.",
        english:
          "When the purchased product arrives in our stock, we will register it and the order link that was created at the beginning will change and be marked as delivered and you will be able to view the product in the SUITE menu.",
      },
    },
    {
      image: celular,
      text: {
        portuguese:
          "Qualquer dúvida entre em contato, estamos aqui para ajudar, obrigado!",
        english:
          "If you have any questions, please contact us, we are here to help, thank you!",
      },
    },
  ];
  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={1}
        width={"100%"}
        pt={5}
        pb={5}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={1}
          justifyContent={"center"}
          alignItems={"center"}
          height={isSmalldesktop ? 300 : 490}
        >
          <img
            src={texts[activeIndex].image}
            style={{ maxWidth: isSmalldesktop ? 300 : 600 }}
            alt="example"
          />
          <Typography textAlign={"center"}>
            {texts[activeIndex].text[language]}
          </Typography>
        </Box>

        <Box
          display={"flex"}
          gap={1}
          width={"100%"}
          justifyContent={"center"}
          mt={10}
        >
          {Array.from({ length: texts.length }).map((_item, index) => (
            <IconButton
              size="small"
              sx={{
                color: activeIndex === index ? blue[300] : "",
                width: 20,
                height: 20,
              }}
              onClick={() => setActiveIndex(index)}
            >
              <FiberManualRecordIcon />
            </IconButton>
          ))}
        </Box>
      </Box>
    </FadeInContainer>
  );
}
