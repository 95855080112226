import { useState } from "react";
import {
  BoxWithTitle,
  ProductsCards,
} from "../../../components/AtomicDesign/Molecules";
import useAuth from "../../../hooks/context/useAuth";
import { useTranslate } from "../../../hooks/useTranslate";

import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { FadeInContainer } from "../../../components/AtomicDesign/Atoms";
import { Box } from "@mui/material";
import {
  CustomModal,
  NewProductBox,
} from "../../../components/AtomicDesign/Organisms";
import { useBreakpoints } from "../../../hooks/useBreakpoins";
import useProductsAdmin from "../../../hooks/context/useProductsAdmin";

export function UsersProductsAdmin() {
  const {
    cleanFilters,
    setHandleIDProduct,
    submitProduct,
    usersData,
    setAttData,
    records,
    productsData,
    loading,
    product,
    setProduct,
    productRemoveImage,
    updateProduct,
    productSolicitationAdmin,
    deleteProduct,
    readAllMessages,
    sendNewMessage,
    getOrders,
  } = useProductsAdmin();
  const { strings } = useTranslate();
  const { language } = useAuth();
  const { getFilterStorage, setFilters } = useLocalStorage();
  const { isSmalldesktop } = useBreakpoints();

  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
    setHandleIDProduct(null);
    setProduct({});
  };

  return (
    <FadeInContainer>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        justifyContent={"center"}
        alignContent={"center"}
        gap={5}
        p={1}
      >
        <BoxWithTitle
          title={strings.productsPage.titleAdmin[language]}
          width="100%"
          childrenProps={{ height: "100%" }}
          padding={1}
          // button
          hasButton
          buttonTitle={strings.buttons.newProduct[language]}
          onClick={() => setModalOpen(true)}
          // button
          // filter
          hasFilter
          onSelectField={(val) => setFilters("key", val)}
          selectedField={getFilterStorage().key}
          onChangeTextField={(val) => {
            setFilters("searchTerm", val);
            setAttData((bool: boolean) => !bool);
          }}
          searchTerm={getFilterStorage().searchTerm}
          onClickCleanFilter={cleanFilters}
          // filter
          // pagnation
          hasPagination
          itensPerpage={(val) => {
            setFilters("itensPerPage", val);
            setAttData((bool: boolean) => !bool);
          }}
          itensPerPage={getFilterStorage().itensPerPage} // quantidade
          howManyPages={Math.ceil(records / getFilterStorage().itensPerPage)}
          onChangePage={(page) => {
            setFilters("offset", page - 1);
            setAttData((bool: boolean) => !bool);
          }}
          // pagnation
        >
          <ProductsCards
            data={productsData}
            onClickCredit={(val) => {
              setHandleIDProduct(val?.id);
              setModalOpen(true);
            }}
            isLoading={loading.productsLoading}
          />
        </BoxWithTitle>
      </Box>
      <CustomModal
        openModal={modalOpen}
        setOpenModal={closeModal}
        width={isSmalldesktop ? "90%" : "40%"}
      >
        <NewProductBox
          readAllMessages={readAllMessages}
          sendNewMessage={sendNewMessage}
          submitLoading={loading.submitProduct}
          userList={usersData ?? []}
          getOrders={(suiteID: number) => getOrders(suiteID)}
          handleSubmit={async (values) => {
            if (values.type === "create") {
              const response = await submitProduct(values);
              if (response) return closeModal();
            } else {
              updateProduct(values);
            }
          }}
          product={product}
          isFileLoading={false}
          isLoading={loading.oneOrder}
          removeFileAdmin={productRemoveImage}
          deleteProduct={(id) => {
            deleteProduct(id);
            setModalOpen(false);
          }}
          productSolicitationAdmin={productSolicitationAdmin}
        />
      </CustomModal>
    </FadeInContainer>
  );
}
