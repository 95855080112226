import { Box, Divider, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../../hooks/useTranslate";
import useAuth from "../../../../hooks/context/useAuth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";
import { CustomModal } from "../../Organisms";
import { getAlertsClientService } from "../../../../services/siteConfigService";
import { useLocation } from "react-router-dom";

export function AlertBox() {
  const { strings } = useTranslate();
  const { pathname } = useLocation();
  const { language, alerts, isAdmin } = useAuth();
  const { isMobile, isSmalldesktop } = useBreakpoints();
  const [hiddenMoreAlerts, setHiddenMoreAlerts] = useState(true);
  const [oldData, setOldData] = useState([] as AlertsConfigProps[]);
  const [openModal, setopenModal] = useState({ open: false, alert: null } as {
    open: boolean;
    alert: AlertsConfigProps | null;
  });

  const getAlerts = async () => {
    try {
      const response = await getAlertsClientService();
      setOldData(response);
    } catch {
      setOldData([]);
    }
  };

  useEffect(() => {
    if (isAdmin) {
      getAlerts();
    } else {
      setOldData(alerts);
    }
    // eslint-disable-next-line
  }, [pathname]);

  const getWidth = () => {
    if (isMobile) {
      return "90%";
    }
    if (isSmalldesktop) {
      return "90%";
    }
    return "65%";
  };

  const fwidth = () => {
    if (isMobile) {
      return "100%";
    }
    if (isSmalldesktop) {
      return "70%";
    }
    return "60%";
  };

  return (
    <Box width={fwidth()} display={"flex"} justifyContent={"center"}>
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={0.2}
        mt={isMobile ? 5 : 1}
        justifyContent={"center"}
        alignItems={"center"}
        style={{ width: getWidth() }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
          sx={{
            overflow: hiddenMoreAlerts ? "hidden" : "visible",
          }}
          height={hiddenMoreAlerts ? 107 : "auto"}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            gap={0.2}
            width={"100%"}
          >
            {oldData?.length > 0 &&
              oldData?.map((alert, index) => (
                <Box
                  key={index}
                  sx={{
                    background: alert?.backgroundColor,
                    p: 1,
                    overflow: "hidden",
                    width: "100%",
                    cursor: "pointer",
                    "&:hover": { opacity: 0.6 },
                  }}
                  onClick={() => setopenModal({ open: true, alert })}
                >
                  <Typography
                    sx={{
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      color: alert?.textColor,
                      fontSize: 12,
                    }}
                  >
                    {language === "english"
                      ? alert?.english
                      : alert?.portuguese}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>

        {oldData.length > 3 && (
          <Box
            width={"80%"}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            mt={1}
            onClick={() => setHiddenMoreAlerts((old) => !old)}
            sx={{ cursor: "pointer" }}
          >
            <Divider sx={{ width: "100%" }} />
            <Typography fontWeight={500}>
              {hiddenMoreAlerts
                ? strings.siteAlertsPage.buttonShowAlert[language]
                : strings.siteAlertsPage.buttonHiddenAlert[language]}
            </Typography>
            {hiddenMoreAlerts ? (
              <KeyboardArrowDownIcon sx={{ mt: -1 }} />
            ) : (
              <KeyboardArrowUpIcon sx={{ mt: -1 }} />
            )}
          </Box>
        )}
      </Box>
      <CustomModal
        openModal={openModal?.open}
        setOpenModal={() => setopenModal({ open: false, alert: null })}
      >
        <Box width="100%" justifyContent="center" display="flex">
          <Box
            sx={{
              background: openModal?.alert?.backgroundColor,
              p: 1,
              width: "90%",
            }}
          >
            <Typography
              sx={{
                color: openModal?.alert?.textColor,
              }}
            >
              {language === "english"
                ? openModal?.alert?.english
                : openModal?.alert?.portuguese}
            </Typography>
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
}
