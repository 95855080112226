import { Box, Button, Typography } from "@mui/material";
import { CustomModal } from "../../Organisms";
import { UseDateTime } from "../../../../hooks/useDateTime";
import { useCommonData } from "../../../../hooks/useCommonData";
import { TextCard } from "../TextCard";
import { useState } from "react";
import { useBreakpoints } from "../../../../hooks/useBreakpoins";

interface Props {
  product: Products | undefined;
}

export function ProductPreview({ product }: Props) {
  const { isSmalldesktop } = useBreakpoints();
  const { formatedDate } = UseDateTime();
  const { productPreview } = useCommonData();
  const [open, setOpen] = useState(false);
  return (
    <Box>
      {product && (
        <Button
          variant="contained"
          sx={{ fontSize: 10 }}
          onClick={() => setOpen(true)}
        >
          {productPreview.button}
        </Button>
      )}
      <CustomModal
        openModal={open}
        setOpenModal={setOpen}
        title={productPreview.previewTitle}
      >
        <Box
          display={"flex"}
          gap={1}
          flexDirection={isSmalldesktop ? "column" : "row"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <img
            src={product?.ProductImages[0]?.code}
            alt="product"
            width={200}
            style={{ borderRadius: 5 }}
          />
          <Box>
            <TextCard padding={"1px"} text={String(product?.id)} title="ID" />
            <TextCard
              padding={"1px"}
              text={product?.name ?? ""}
              title={productPreview.name}
            />
            <TextCard
              padding={"1px"}
              text={String(product?.itemsQuantity) ?? ""}
              title={productPreview.quantity}
            />
            <TextCard
              padding={"1px"}
              text={String(
                product?.arrivedAt && formatedDate(product?.arrivedAt)
              )}
              title={productPreview.date}
            />
          </Box>
        </Box>
        <Typography sx={{ textAlign: "center", mt: 1 }}>
          {productPreview.details}
        </Typography>
      </CustomModal>
    </Box>
  );
}
